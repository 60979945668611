import fourzerothree from '../../assets/images/403.svg';
import fourzerofour from '../../assets/images/404.svg';
import fivezerozero from '../../assets/images/500.svg';
import maintenance from '../../assets/images/maintenance.svg';
import userNotFound from '../../assets/icons/user-not-found.png';
import ErrorScreen from './ErrorScreen';

const listOfErrors = [
  {
    id: 403,
    title: 'Access Denied!',
    subtitle: 'You don’t have permission to access this, please contact your HR',
    image: fourzerothree,
  },
  {
    id: 404,
    title: 'Error 404 - Not Found',
    subtitle: 'The page you are looking for could not be found!',
    image: fourzerofour,
  },
  // { id: 500, title: "Oops, something went wrong", subtitle: "The server encountered an error and was unable to complete your request", image: fivezerozero },
  {
    id: 503,
    title: 'We’ll be back shortly!',
    subtitle: 'Our servers are under maintenance. Please try again after some time',
    image: maintenance,
  },
  {
    id: 500,
    title: 'We’ll be back shortly!!',
    subtitle: 'Our servers are under maintenance. Please try again after some time',
    image: fivezerozero,
  },
  {
    id: 4001,
    title: 'User Does Not Exists',
    subtitle: 'We are sorry, but it appears that the user is invalid or not active yet!',
    image: userNotFound,
  },
];

export const returnErrorPage = (key: number, showCTA?: boolean, isFullscreen?: boolean) => {
  const error = listOfErrors?.find((item: any) => item?.id === key);
  return <ErrorScreen title={error?.title} subtitle={error?.subtitle} image={error?.image} showCTA={key !== 403 ? showCTA : false} isFullscreen={isFullscreen || key === 403} />;
};
