import { Image, Row, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../UI/Button/Button';
import './_error.scss';
import { useSelector } from 'react-redux';
interface Props {
  title: string | undefined;
  subtitle: string | undefined;
  image: string | undefined;
  showCTA?: boolean;
  isFullscreen?: boolean;
}

const ErrorScreen: React.FC<Props> = ({ title, subtitle, image, showCTA = true, isFullscreen = false }) => {
  const navigate = useNavigate(),
    logo = useSelector((state: any) => state.theme.logoUrl);

  return (
    <Row justify="center" align="middle" className={isFullscreen ? 'full-screen-error' : 'center-height'}>
      <div className="error-screen">
        {isFullscreen && (
          <Row justify="center">
            {!logo && <Skeleton.Image className="logo" />}
            {logo && <Image src={logo} preview={false} className="logo"/>}
          </Row>
        )}
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <Image src={image} preview={false} className="image" />
        {showCTA && (
          <Row justify="center" align="middle" className="cta">
            <PrimaryButton onClick={() => navigate(-1)}>BACK TO PREVIOUS SCREEN</PrimaryButton>
          </Row>
        )}
      </div>
    </Row>
  );
};

export default ErrorScreen;
