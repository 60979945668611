import { LeftOutlined } from '@ant-design/icons';
import { Col, Dropdown, Image, Row, Space } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { thunks } from '../../thunks/auth';
import { toTitleCase } from '../../utils/helper';
import { ContactModal, CustomModal } from '../Modals/CustomModal';
import NavbarMobile from '../Navbar/NavbarMobile';
import './_header-strip.scss';
import { HeadsetHelp, HelpCircle, LogOut, NavArrowDown, User, UserCircle } from 'iconoir-react';
import { SecondaryButton } from '../UI/Button/Button';
import { authActions } from '../../store/auth';
import { trackData } from '../../utils/trackers';

const HeaderStrip: React.FC = () => {
  const dispatch = useDispatch(),
    [openContact, setOpenContact] = useState<boolean>(false),
    logoutHandler = () => {
      dispatch(thunks.logout());
    },
    themeDetails = useSelector((state: any) => state.theme),
    navigate = useNavigate(),
    [modalVisible, setModalVisible] = useState(false),
    name = useSelector((state: any) => state.auth.name),
    [visible, setVisible] = useState(false),
    relationship_managers = useSelector((state: any) => state.auth.relationship_managers),
    activeMode = useSelector((state: any) => state.auth.activeMode),
    isEmployer = useSelector((state: any) => state.auth.roles)?.includes('employer'),
    onChangeHandler = (val: 'employer' | 'employee') => {
      if (val === 'employee') {
        dispatch(authActions.changeActiveMode('employer'));
        trackData('Toggle Role', { 'Toggled To': 'employer' });
      } else {
        dispatch(authActions.changeActiveMode('employee'));
        trackData('Toggle Role', { 'Toggled To': 'employee' });
      }
    },
    navigateToKnowledgeCenter = () => {
      let url = 'https://learn.zohopublic.in/external/manual/member-knowledge-base/article/manual-client-dashboard-employee?p=ceeef6965bc937132fb23f08ade6a696c22c654f33a88f1463f4ff6ae520cfd3';
      if (activeMode === 'employer') {
        url = 'https://learn.zohopublic.in/external/manual/gbp-partnerclient-knowledge-base/article/employer-handguide-getting-started?p=ceeef6965bc937132fb23f08ade6a696161bc54aabfd18e35f246f1ef7130518';
      }
      window.open(url, '_blank');
    },
    menu = () => {
      return (
        <div className="header-menu">
          {isEmployer && <div className='item'>
            <SecondaryButton block onClick={() => onChangeHandler(activeMode)}>SWITCH TO {activeMode === 'employee' ? 'ADMIN' : 'MEMBER'}</SecondaryButton>
          </div>}
          <div className="item item-text" onClick={() => navigate('profile')}>

            <User />

            Profile
          </div>
          <div className="item item-text" onClick={navigateToKnowledgeCenter}>

            <HelpCircle />

            Help Center
          </div>
          <div className="item item-text" onClick={() => setModalVisible(true)}>

            <LogOut />

            Logout
          </div>
        </div>
      );
    },
    closeNavbar = () => {
      setVisible(false);
    },
    location = useLocation(),
    pathSegments = location.pathname.split('/').filter((segment) => segment),
    isUUID = (str: string) => {
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      return uuidRegex.test(str);
    },
    title = ((): { isCustom?: boolean; value: string } => {
      if (pathSegments.length === 0) return { value: 'Home', isCustom: true };
      const lastSegment = pathSegments[pathSegments.length - 1];

      if (pathSegments.includes('claims') && pathSegments.length > 1) {
        if (isUUID(lastSegment) || lastSegment === 'undefined') {
          const claimId = (location?.state as { claim: { claim_id: string } })?.claim?.claim_id;
          return { value: `Claim Details${claimId ? `: ${claimId}` : ''}`, isCustom: true };
        }
      }

      if (!isNaN(Number(lastSegment))) {
        if (pathSegments.length > 1) return { value: pathSegments[pathSegments.length - 2], isCustom: false };
        return { value: 'Home', isCustom: true };
      }

      return { value: lastSegment, isCustom: false };
    })(),
    isHomeInURL = location.pathname.includes('home'),
    brandName = useSelector((state: any) => state.theme.brandName);

  const goBack = () => {
    if (pathSegments.length > 1) {
      // Remove the last segment and join the segments to form the new URL
      pathSegments.pop();
      const newPath = '/' + pathSegments.join('/');
      if (newPath?.includes('how-to-claim')) {
        navigate('claims');
      } else {
        navigate(newPath);
      }
    }
  };

  return (
    <>
      {visible && <NavbarMobile close={closeNavbar} visible={visible} />}

      <CustomModal
        isModalVisible={modalVisible}
        confirmHandler={logoutHandler}
        cancelHandler={() => {
          setModalVisible(false);
        }}
        title={`Are you sure you want to logout?`}
        cancelButtonText="NO"
        confirmButtonText="YES"
      />
      <ContactModal isModalVisible={openContact} cancelHandler={() => setOpenContact(false)} data={
        {
          ...(activeMode === 'employer' && {
            relationship_manager: {
              full_name: relationship_managers?.policy_manager?.full_name,
              phone: relationship_managers?.policy_manager?.phone,
              email: relationship_managers?.policy_manager?.email,
            }
          }),
          claim_manager: {
            full_name: relationship_managers?.claim_manager?.full_name,
            phone: relationship_managers?.claim_manager?.phone,
            email: relationship_managers?.claim_manager?.email,
          },
        }
      } />
      <div className="header-strip-parent">
        <Row justify="space-between" align="middle" className="header-strip header-web">
          <Col>
            <Image
              className="pointer header-logo"
              src={themeDetails?.logoUrl}
              preview={false}
              onClick={() => navigate('/')}
            />
          </Col>
          <Col className="header-web">
            <Row align="bottom" gutter={24}>
              <Col>
                <Row className='icon-button-with-text button-shadow pointer' align='middle' gutter={8} onClick={() => setOpenContact(true)}>
                  <Col>
                    <HeadsetHelp />
                  </Col>
                  <Col>
                    Support
                  </Col>
                </Row>
              </Col>
              <Col className='pointer'>
                <Dropdown trigger={['click']} overlay={menu}>
                  <Row className='icon-button-with-text pointer' align='top' gutter={8}>
                    <Col>
                      <UserCircle />
                    </Col>
                    <Col>
                      {name}
                    </Col>
                    <Col><NavArrowDown /></Col>
                  </Row>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        {isHomeInURL && (
          <Row justify="center" align="middle" className={`header-strip show-on-mobile`}>
            <Col>
              <Image
                className="pointer header-logo"
                src={themeDetails?.logoUrl}
                preview={false}
                onClick={() => navigate('/')}
              />
            </Col>
          </Row>
        )}

        {!isHomeInURL && (
          <Row justify="start" align="middle" className={`header-strip show-on-mobile primary-bg`}>
            {pathSegments.length > 1 && (
              <Col>
                <span className="back-button" onClick={goBack}>
                  <LeftOutlined />
                </span>
              </Col>
            )}
            <Col>
              <span className="title">
                {title?.isCustom ? title?.value : toTitleCase(title?.value?.replaceAll('-', ' '))}
              </span>
            </Col>
          </Row>
        )}

        {brandName && (
          <Row className="extend-love-badge show-on-mobile" justify="center">
            <Col>
              <Row align="middle">
                <Col className="we">We</Col>
                <Col className="heart">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clipPath="url(#clip0_11605_77695)">
                      <rect width="14" height="14" fill="white" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.9934 2.99589C5.82711 1.6324 3.88225 1.26562 2.42097 2.51417C0.959688 3.76272 0.75396 5.85024 1.90151 7.3269C2.85562 8.55465 5.7431 11.1441 6.68945 11.9822C6.79533 12.0759 6.84827 12.1228 6.91002 12.1412C6.96391 12.1573 7.02289 12.1573 7.07678 12.1412C7.13853 12.1228 7.19147 12.0759 7.29735 11.9822C8.24371 11.1441 11.1312 8.55465 12.0853 7.3269C13.2328 5.85024 13.0522 3.74959 11.5658 2.51417C10.0794 1.27876 8.15969 1.6324 6.9934 2.99589Z"
                        fill="#E27575"
                        stroke="#E27575"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_11605_77695">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Col>
                <Col className="organisation">{brandName}</Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default HeaderStrip;
