import { Badge, Col, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavbarLink from './NavbarLink';
import { Community, DatabaseSettings, Gift, HomeAlt, MultiplePages } from 'iconoir-react';

interface MenuProps {
  name: string;
  icon: any;
  link: string;
  highlight: boolean;
}

const Navbar: React.FC = () => {
  const activeMode = useSelector((state: any) => state.auth.activeMode),
    [activeMenu, setActiveMenu] = useState<Array<MenuProps>>([]),
    navigate = useNavigate(),
    userDetails = useSelector((state: any) => state.data.userDetails),
    brandName = useSelector((state: any) => state.theme.brandName),
    employeeMenu = [
      {
        name: 'Home',
        icon: <HomeAlt style={{ height: '24px', width: '24px' }} />,
        link: '/home',
        highlight: false,
      },
      {
        id: 2,
        name: 'Claims',
        icon: <MultiplePages style={{ height: '24px', width: '24px' }} />,
        link: '/claims',
        highlight: false,
      },
      {
        id: 3,
        name: 'Offers',
        icon: <Gift style={{ height: '24px', width: '24px' }} />,
        link: '/retail/?utm_source=platform&utm_medium=navbar_btn&utm_campaign=employee_offer',
        highlight: false,
      }
    ],
    employerMenu = [
      {
        name: 'Dashboard',
        icon: <HomeAlt style={{ height: '24px', width: '24px' }} />,
        link: '/dashboard',
        highlight: false,
      },
      {
        name: 'Members',
        icon: <Community style={{ height: '24px', width: '24px' }} />,
        link: '/members',
        highlight: false,
      },
      ...(userDetails?.admin_claims_view_access
        ? [
          {
            name: 'Claims',
            icon: <MultiplePages style={{ height: '24px', width: '24px' }} />,
            link: '/claims',
            highlight: false,
          },
        ]
        : []),
      {
        name: 'Integrations',
        icon: <DatabaseSettings style={{ height: '24px', width: '24px' }} />,
        link: '/integrations',
        highlight: false,
      },
    ];

  useEffect(() => {
    if (activeMode === 'employer') {
      setActiveMenu(employerMenu);
    } else {
      setActiveMenu(employeeMenu);
    }
  }, [activeMode, userDetails?.admin_claims_view_access]);

  return (
    <div className="navbar">
      <div className="navbar-items">
        {activeMenu.map((item, index) => (
          <NavbarLink to={item.link} key={index} highlight={item.highlight}>
            <Row align='top' key={index} className='hide-in-collapsed-navbar'>
                <Col className='navbar-icon'>{item.icon}</Col>
              <Col>
                <span className="nav-page navbar-text">{item.name}</span>
              </Col>
            </Row>
            <div className='show-in-collapsed-navbar'>
              <Tooltip title={item.name} placement="right">
                <div className='navbar-icon'>{item.icon}</div>
              </Tooltip>
            </div>
          </NavbarLink>
        ))}
      </div>
      <div className="footer">
        {brandName && (
          <div className="love-badge">
            <Row align="middle" gutter={8} justify='center'>
              <Col>
                We
              </Col>
              <Col>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_11615_80448)">
                    <rect width="14" height="14" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.99731 2.99589C5.83102 1.6324 3.88615 1.26562 2.42487 2.51417C0.963594 3.76272 0.757867 5.85024 1.90542 7.3269C2.85953 8.55465 5.747 11.1441 6.69336 11.9822C6.79924 12.0759 6.85218 12.1228 6.91393 12.1412C6.96782 12.1573 7.02679 12.1573 7.08069 12.1412C7.14244 12.1228 7.19538 12.0759 7.30125 11.9822C8.24761 11.1441 11.1351 8.55465 12.0892 7.3269C13.2367 5.85024 13.0561 3.74959 11.5697 2.51417C10.0833 1.27876 8.1636 1.6324 6.99731 2.99589Z"
                      fill="#E27575"
                      stroke="#E27575"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_11615_80448">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Col>
              <Col>{brandName}</Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
