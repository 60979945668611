import { ClearOutlined, CloseOutlined, CopyOutlined, ExclamationCircleOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Alert, Col, DatePicker, Drawer, Form, Image, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import api from '../../api/api';
import { errorHandler, mobileNumber } from '../../utils/helper';
import PrimaryButton, { SecondaryButton } from '../UI/Button/Button';
import TableComponent from '../UI/Table/TableComponent';
import { successNotification } from '../UI/Toast/Toast';
import './_custommodal.scss';

interface Props {
  isModalVisible: boolean;
  modalTitle?: string;
  cancelHandler: () => void;
  confirmHandler?: any;
  type?: string;
  data?: any;
  title?: string;
  subtitle?: string;
  modalButtonLoading?: boolean;
  service?: string;
  stepsToAvail?: any;
  description?: string;
  product?: any;
  logo?: string;
  setData?: any;
  availType?: string;
  coupon_code?: string;
  getSubscriptions?: any;
  cancelButtonText?: string;
  confirmButtonText?: string;
  children?: any;
}

export const CustomModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  title,
  subtitle,
  type,
  modalButtonLoading,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  data,
  children,
}) => {
  if (type === 'confirm-bulk-addition') {
    return (
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={cancelHandler}
        className="confirm-bulk-addition"
        closable={false}
      >
        <Row align="middle">
          <Col span={24}>
            <div className="modal-title">{title}</div>
          </Col>
        </Row>
        <div className="modal-subtitle">
          {subtitle}
          <br />
          <ul className="mt-16">
            {data?.map((item: any, index: number) => {
              return (
                <li key={index}>
                  {item?.key} : {item?.value}
                </li>
              );
            })}
          </ul>
        </div>
        <Row justify="end" className="mt-56">
          <SecondaryButton onClick={cancelHandler}>{cancelButtonText}</SecondaryButton>
          <PrimaryButton onClick={confirmHandler} className="margin-left" loading={modalButtonLoading}>
            {confirmButtonText}
          </PrimaryButton>
        </Row>
      </Modal>
    );
  }
  else {
    return (
      <Modal visible={isModalVisible} footer={null} onCancel={cancelHandler}>
        <Row justify="space-around" align="middle">
          <Col span={24} className="modal-card">
            <div className="modal-icon">
              {type === 'confirm' ? <ExclamationCircleOutlined /> : type === 'delete' ? <ClearOutlined /> : null}
            </div>
            <div className="modal-title">{title}</div>
            <div className="modal-subtitle">{subtitle}</div>
            {children && <div>{children}</div>}
          </Col>
        </Row>
        <div className="modal-footer">
          <SecondaryButton onClick={cancelHandler}>{cancelButtonText}</SecondaryButton>
          <PrimaryButton onClick={confirmHandler} className="margin-left" loading={modalButtonLoading}>
            {confirmButtonText}
          </PrimaryButton>
        </div>
      </Modal>
    );
  }
};


export const ReviewBulkInviteModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  title,
  subtitle,
  modalButtonLoading,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  data,
}) => {
  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      onCancel={cancelHandler}
      className="confirm-bulk-invitation"
      closable={false}
    >
      <Row align="middle">
        <Col span={24}>
          <div className="modal-title">{title}</div>
        </Col>
      </Row>
      <div className='mt-24'>{subtitle}</div>
      <div className="mt-16">
        <span className='strong'>Number of invited employees</span> : {data?.eligible?.employees + data?.ineligible?.employees}

        <div className='mt-16'>Eligible for auto-raising endorsement</div>
        <div className='strong'>Employees : {data?.eligible?.employees}</div>
        <div className='strong'>Lives : {data?.eligible?.lives}</div>
        <div className='mt-16'>Endorsements subject to member confirming the details</div>
        <div className='strong'>Employees : {data?.ineligible?.employees}</div>
        <div className='strong'>Lives : {data?.ineligible?.lives}</div>
      </div>
      <Row justify="end" className="mt-56">
        <SecondaryButton onClick={cancelHandler}>{cancelButtonText}</SecondaryButton>
        <PrimaryButton onClick={confirmHandler} className="margin-left" loading={modalButtonLoading}>
          {confirmButtonText}
        </PrimaryButton>
      </Row>
    </Modal>
  );
};



export const MemberDeletionModal: React.FC<Props> = ({
  isModalVisible,
  confirmHandler,
  cancelHandler,
  modalButtonLoading,
  data,
  // buttonLoading,
}) => {
  const [form] = useForm(),
    deleteData = () => {
      form.validateFields().then((values) => {
        data.setDateOfLeaving(moment(values?.date_of_leaving))
      })
    };

  useEffect(() => {
    if (data?.dateOfLeaving && data?.data?.uuid) confirmHandler();
  }, [data.dateOfLeaving]);

  return (
    <Modal visible={isModalVisible} footer={null} onCancel={cancelHandler} destroyOnClose>
      <Row justify="space-around" align="middle">
        <Col span={24} className="modal-card">
          <div className="modal-icon">
            <ClearOutlined />
          </div>
          <div className="modal-title">Member Deletion</div>
          <div className="modal-subtitle">Enter a date of leaving to confirm the following deletion</div>
          <div className="mt-32" />
          <Form
            form={form}>
            <TableComponent
              columns={[
                {
                  title: 'Employee ID',
                  dataIndex: ['organisation', 'employee_id'],
                  key: 'employee_id',
                },
                {
                  title: 'Name',
                  dataIndex: 'full_name',
                  key: 'full_name',
                },
                {
                  title: 'Date of Leaving',
                  dataIndex: 'date_of_leaving',
                  key: 'date_of_leaving',
                  render: () => (
                    <>
                      &nbsp;
                      <Form.Item
                        name={['date_of_leaving']}
                        rules={[{ required: true, message: 'Please select date of leaving' }]}
                      >
                        <DatePicker
                          disabledDate={(current: any) => current.isAfter(moment())}
                          format="DD-MMM-YYYY"
                          suffixIcon
                          allowClear
                        />
                      </Form.Item>
                    </>
                  ),
                }
              ]}
              dataSource={[data?.data]}
              setRowKey="uuid"
            />
          </Form>
        </Col>
      </Row>
      <div className="modal-footer">
        <SecondaryButton onClick={cancelHandler}>Cancel</SecondaryButton>
        <PrimaryButton onClick={deleteData} className="margin-left" loading={modalButtonLoading}>Delete</PrimaryButton>
      </div>
    </Modal>
  );
};
const createMarkup = (html: any) => {
  return {
    __html: html,
  };
};

export const AvailSubscriptionModal: React.FC<Props> = ({
  isModalVisible,
  cancelHandler,
  logo,
  product,
  stepsToAvail,
  description,
  availType,
  coupon_code,
  getSubscriptions,
}) => {
  const [couponCode, setCouponCode] = useState(coupon_code),
    [btnLoading, setBtnLoading] = useState(false),
    [flag, setFlag] = useState(false);

  const generateCoupon = () => {
    setBtnLoading(true);

    api.products
      .getCouponCode({ product_id: product.id })
      .then((res) => {
        setCouponCode(res.data);
        setFlag(true);
        setBtnLoading(false);
      })
      .catch((err) => {
        errorHandler(err?.response?.data);
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      if (flag) {
        getSubscriptions();
      }
    };
  }, []);

  return (
    <Modal visible={isModalVisible} footer={null} onCancel={cancelHandler} className="custom-modal" width={'560px'}>
      <div className="avail-subscription-modal">
        <div className="title">{product.name}</div>
        <div className="subtitle">{description}</div>

        {availType === 'coupon' && (
          <>
            {!couponCode && (
              <div className="margin-top margin-bottom">
                <SecondaryButton loading={btnLoading} onClick={generateCoupon}>
                  Generate Coupon
                </SecondaryButton>
              </div>
            )}

            {couponCode && (
              <div className="coupon-code">
                <span className="coupon-title">Coupon Code: </span>
                <span className="coupon-code-value"> {couponCode}</span>
                <span className="copy-coupon-code">
                  <CopyToClipboard text={couponCode} onCopy={() => successNotification('Coupon code copied')}>
                    <CopyOutlined />
                  </CopyToClipboard>
                </span>
              </div>
            )}
          </>
        )}

        <div className="steps-title">Steps to avail the benefits</div>
        <div className="steps dangerous_html" dangerouslySetInnerHTML={createMarkup(stepsToAvail)}></div>

        <div className="powered-by">
          Powered by <Image src={logo} preview={false} className="provider-logo" />
        </div>
      </div>
    </Modal>
  );
};

// ...(activeMode === 'employer' && {
//   relationship_manager: {
//     full_name: relationship_managers?.policy_manager?.full_name,
//     phone: relationship_managers?.policy_manager?.phone,
//     email: relationship_managers?.policy_manager?.email,
//   }
// }),
// claim_manager: {
//   full_name: relationship_managers?.claim_manager?.full_name,
//   phone: relationship_managers?.claim_manager?.phone,
//   email: relationship_managers?.claim_manager?.email,
// },

export const ContactModal: React.FC<Props> = ({
  isModalVisible,
  cancelHandler,
  data,
}) => {
  return (
    <Modal visible={isModalVisible} footer={null} onCancel={cancelHandler} className="contact-modal">
      <div className="title">We've got you covered!</div>
      <div className="subtitle">Claims Relationship Manager</div>
      <div className="item">
        <div>{data?.claim_manager?.full_name}</div>
        <a href={`tel:${data?.claim_manager?.phone}`} className="contact-link">
          <PhoneOutlined rotate={90} /> {data?.claim_manager?.phone}
        </a>
        <a href={`mailto:${data?.claim_manager?.email}`} className="contact-link">
          <MailOutlined /> {data?.claim_manager?.email}
        </a>
      </div>

      {data?.relationship_manager && <>
        <div className="subtitle">Account Relationship Manager</div>
        <div className="item">
          <div>{data?.relationship_manager?.full_name}</div>
          <a href={`tel:${data?.relationship_manager?.phone}`} className="contact-link">
            <PhoneOutlined rotate={90} /> {data?.relationship_manager?.phone}
          </a>
          <a href={`mailto:${data?.relationship_manager?.email}`} className="contact-link">
            <MailOutlined /> {data?.relationship_manager?.email}
          </a>
        </div>
      </>}
    </Modal>
  );
};

export const ClaimDocumentsModal: React.FC<Props> = ({
  isModalVisible,
  cancelHandler,
  data,
}) => {
  const columns = [
    {
      title: 'Document Name',
      dataIndex: 'heading',
      key: 'heading',
      width: '30%',
      render: (text: any, record: any) => <span className={`${record?.mandatory ? `required` : ''}`}>{record?.heading}</span>,
    },
    {
      title: 'Description',
      dataIndex: ['parameters', 'description'],
      key: 'description',
      width: '70%',
      render: (value: any, record: any) => {
        if (record?.type !== 'header') {
          return (
            <span className='dangerous_html' dangerouslySetInnerHTML={{ __html: value }} />
          )
        }
      }
    }],
    mobileColumns = [
      {
        title: "Document Name",
        dataIndex: 'heading',
        key: 'heading',
        width: '100%',
        render: (text: any, record: any) => {
          if (record?.type === "header") {
            return <span style={{ fontWeight: "500", padding: "8px 24px" }} className={`${record?.mandatory ? `required` : ''}`}>{record?.heading}</span>
          } else {
            return <div style={{ padding: "24px" }}>
              <div className={`${record?.mandatory ? `required` : ''}`}>{record?.heading}</div>
              <div className='mt-16 dangerous_html' dangerouslySetInnerHTML={{ __html: record?.parameters?.description }} />
            </div>
          }
        }
      }
    ],
    reformatData = (data: any) => {
      let temp = []
      for (let doc of data) {
        temp.push({ heading: doc.name, type: 'header' })
        temp.push(...doc.type)
      }
      return temp;
    },
    modelElement = <>
      <div className="close-cta" onClick={cancelHandler}>
        <CloseOutlined className="close-icon pointer" />
      </div>
      <Row align="top" justify="space-between" className="details-section">
        <Col>
          <div className="title">Document List - Reimbursement Claims</div>
          <div className="subtitle">GROUP HEALTH INSURANCE</div>
        </Col>
      </Row>
      <Alert type="info" message={<span>These are the documents required from you to process the claims. All documents marked with <span style={{ color: 'red' }}>*</span> are mandatory.</span>} showIcon className="mt-24 mb-16" />
      <div className='claim-documents'>
        <TableComponent
          columns={columns}
          dataSource={reformatData(data?.filter((item: any) => item?.heading !== "Letter"))}
          showPagination={false}
          rowClassName={(record: any) => {
            if (record?.type === "header") {
              return "header-row"
            } else return null
          }}
        />
      </div>
    </>,
    drawerElement = <>
      <div className="subtitle">GROUP HEALTH INSURANCE</div>
      <Alert type="info" message={<span>These are the documents required from you to process the claims. All documents marked with <span style={{ color: 'red' }}>*</span> are mandatory.</span>} showIcon className="mt-24 mb-16" />
      <div className='claim-documents mobile-table'>
        <TableComponent
          columns={mobileColumns}
          dataSource={reformatData(data?.filter((item: any) => item?.heading !== "Letter"))}
          showPagination={false}
          rowClassName={(record: any) => {
            if (record?.type === "header") {
              return "header-row"
            } else return null
          }}
        />
      </div>
    </>;

  return (
    <>
      <Modal width={960} visible={isModalVisible} footer={null} closable={false} onCancel={cancelHandler} className="claim-documents-modal show-on-web">
        {modelElement}
      </Modal>
      <Drawer width={'100vw'} closable={true} title={"Document List - Reimbursement Claims"} visible={isModalVisible} destroyOnClose footer={null} onClose={cancelHandler} className="claim-documents-modal show-on-mobile">
        {drawerElement}
      </Drawer>
    </>
  );
};
